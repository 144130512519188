import * as React from "react";
import AssetPage from "../../components/asset-page";

const PolygonPage = () => (
  <AssetPage
    asset="Polygon"
    ticker="MATIC"
    description={[
      `Polygon, formerly known as Matic Network, has swiftly emerged as a key solution to some of Ethereum's most pressing issues, including scalability and high transaction fees. Launched by Jaynti Kanani, Sandeep Nailwal, and Anurag Arjun, Polygon is designed to enhance the speed, efficiency, and flexibility of decentralized applications (dApps) on the Ethereum blockchain. What sets it apart is its mission to establish a multi-chain system on Ethereum, acting as a "Layer 2" scaling solution, while also maintaining attributes of standalone blockchains, or "sidechains."`,
      `In essence, Polygon's rise as a prominent scaling solution and its emphasis on fostering a vibrant multi-chain ecosystem underscores its pivotal role in the evolving blockchain domain. By addressing Ethereum's limitations and offering tools to innovate, Polygon showcases a promising future in the decentralized landscape.`,
    ]}
    hideFeatureImage
    features={[
      {
        title: "Scalability and Speed",
        text: "At the core of Polygon's design is its commitment to scalability. By employing a decentralized Plasma operator mechanism, it processes transactions off the main Ethereum chain, only submitting them once confirmed. This process significantly boosts transaction speed, enabling thousands of transactions per second (TPS), making dApps more user-friendly and efficient.",
      },
      {
        title: "Interoperability",
        text: "Polygon stands out with its vision of a multi-chain Ethereum ecosystem. It's not limited to just one layer-2 solution; instead, it aims to establish a framework for building interoperable blockchain networks. This adaptability allows developers to harness a variety of tools, ensuring their dApps are as efficient and user-friendly as possible.",
      },
      {
        title: "Enhanced Security",
        text: "With a hybrid Proof-of-Stake (PoS) and Plasma framework, Polygon provides an extra layer of security for its users. While the PoS consensus ensures faster and efficient validation of transactions, the Plasma framework builds on Ethereum's security, ensuring that assets on Polygon are as secure as those on the Ethereum mainnet. This dual approach offers both speed and trustworthiness, making Polygon a sought-after solution in the Ethereum ecosystem.",
      },
    ]}
  />
);

export default PolygonPage;
